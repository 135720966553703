<template>   
    <v-card class="mx-auto">
        <v-container>
			<v-row>
				<v-col lg="12" class="pt-2 pb-1">
                    <v-container>
                        <row >
                            <v-col>                                
                                <v-row>
                                    <v-col cols="12" md="4" >
                                        <s-date label="Fecha" v-model="DateBegin">                    
                                        </s-date>
                                    </v-col>
                                    <v-col cols="12" md="4" >
                                        <s-select
                                            label="Lineas"
                                            v-model="LncID"
                                            :items="listLineas"
                                            item-value="LncID"
                                            item-text="LncDescription">
                                        </s-select>
                                    </v-col>
                                    <v-col cols="12" md="4" >
                                        <s-text 
                                            label="Yuliano" 
                                            v-model="Yuliano">                    
                                        </s-text>
                                    </v-col>
                                </v-row>                                
                            </v-col>
                        </row >
                        <row dense>
                            <v-col cols="12">
                                <v-sheet class="pa-6 text-white mx-auto" rounded="lg"
    color="#F08080">
                                <v-row dense>
                                    <v-col cols="12" md="2" >
                                        <s-date label="Fecha" v-model="DateBegin">                    
                                        </s-date>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <s-select
                                            label="Lineas"
                                            v-model="LncID"
                                            :items="listLineas"
                                            item-value="LncID"
                                            item-text="LncDescription">
                                        </s-select>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <s-text 
                                            label="Yuliano" 
                                            v-model="Yuliano">                    
                                        </s-text>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <s-date label="Fecha" v-model="DateBegin">                    
                                        </s-date>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <s-select
                                            label="Lineas"
                                            v-model="LncID"
                                            :items="listLineas"
                                            item-value="LncID"
                                            item-text="LncDescription">
                                        </s-select>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <s-text 
                                            label="Yuliano" 
                                            v-model="Yuliano">                    
                                        </s-text>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <s-text 
                                            label="Yuliano" 
                                            v-model="Yuliano">                    
                                        </s-text>
                                    </v-col>
                                </v-row>
                                </v-sheet>
                   
                            </v-col>
                        </row>
                    </v-container>     
                </v-col>
            </v-row>
        </v-container>
    </v-card> 
</template>


<script>
import _sCreationTableService from "@/services/FrozenProduction/FrzCreateTableService.js"
export default {
    data(){
        return {
            DateBegin: null,
            LncID: '',
            listLineas: [],
            Yuliano: '',
        }
    },

    mounted()
    {
        _sCreationTableService
			.list(this.$fun.getUserID())
			.then((r) => {
				this.listLineas = r.data;				
				
			});
    }
}
</script>